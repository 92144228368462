
import { defineComponent } from "vue";

import i18n from "../lang";
export default defineComponent({
  name: "UnavailablePage",
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("locale", locale);
        this.$router.go(0);
      }
    },
    isChromeIOS() {
      return navigator.userAgent.indexOf("CriOS") >= 0;
    }
  },
  mounted() {
    if (this.$route.query.locale) {
      this.switchLocale(this.$route.query.locale);
    }
  },
});
